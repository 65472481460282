<template>
  <div class="topbar-item">
    <div id="kt_quick_panel_toggle" class="btn btn-icon btn-hover-transparent-white d-flex align-items-center btn-lg px-md-2 w-md-auto">
      <span class="text-dark-25 font-weight-bolder font-size-base d-none d-md-inline mr-3">{{user.departmentName}}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "KTQuickPanel",
  computed: {
    user: {
      get () {return this.$store.getters.currentUser;}
    },
  },
};
</script>
