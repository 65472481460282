<template>
  <div class="topbar-item" v-if="user && user.recordId">
    <div class="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2" id="kt_quick_user_toggle">
      <span class="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        你好,
      </span>
      <span class="text-dark-25 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {{user.userName}}
      </span>
      <span class="symbol symbol-35 symbol-light-success">
        <img v-if="user.downloadUrl" alt="Pic" :src="user.downloadUrl" />
        <span v-else class="symbol-label font-size-h5 font-weight-bold">
          {{user.empChar}}
        </span>
      </span>
    </div>
    <div id="kt_quick_user" ref="kt_quick_user" class="offcanvas offcanvas-right p-10">
      <!--begin::Header-->
      <div class="offcanvas-header d-flex align-items-center justify-content-between pb-5">
        <h3 class="font-weight-bold m-0">
          用户资料
          <small class="text-muted font-size-sm ml-2">已在该系统使用{{user.useYear}}年</small>
        </h3>
        <a href="#" class="btn btn-xs btn-icon btn-light btn-hover-primary" id="kt_quick_user_close">
          <i class="ki ki-close icon-xs text-muted"></i>
        </a>
      </div>
      <!--end::Header-->
      <!--begin::Content-->
      <perfect-scrollbar class="offcanvas-content pr-5 mr-n5 scroll" style="max-height: 90vh; position: relative;">
        <!--begin::Header-->
        <div class="d-flex align-items-center mt-5">
          <div class="symbol symbol-100 mr-5">
            <img class="symbol-label" v-if="user.downloadUrl" alt="Pic" :src="user.downloadUrl" />
            <span v-else class="symbol-label font-size-h1 font-weight-bold">
              {{user.empChar}}
            </span>
            <i class="symbol-badge bg-success"></i>
          </div>
          <div class="d-flex flex-column">
            <a href="#" class="font-weight-bold font-size-h5 text-dark-75 text-hover-primary">
              {{user.userName}}
            </a>
            <div class="text-muted mt-1">{{user.position}}</div>
            <div class="navi mt-2">
              <a href="#" class="navi-item">
                <span class="navi-link p-0 pb-2">
                  <span class="navi-icon">
                    <i class="fa fa-mobile text-primary"></i>
                  </span>
                  <span class="navi-text text-muted text-hover-primary">
                    {{user.phone}}
                  </span>
                </span>
              </a>
            </div>
            <button type="button" class="btn btn-light-primary btn-bold" @click="onLogout">
              退 出
            </button>
          </div>
        </div>
        <!--end::Header-->
        <div class="separator separator-dashed mt-8 mb-5"></div>
        <!--begin::Nav-->
        <div class="navi navi-spacer-x-0 p-0">
          <!--begin::Item-->
          <div class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/humans/custom-3.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">信息管理</div>
                <div class="text-muted">
                  设置更多的信息&emsp;&emsp;&emsp;
                  <router-link to="/message">
                    <span class="label label-light-primary label-inline font-weight-bold">管理</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end:Item-->
          <!--begin::Item-->
          <div class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/misc/003-puzzle.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">头像管理</div>
                <div class="text-muted">
                  变更自己的头像&emsp;&emsp;&emsp;
                  <router-link to="/logo">
                    <span class="label label-light-primary label-inline font-weight-bold">管理</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end:Item-->
          <!--begin::Item-->
          <div class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/misc/002-eolic-energy.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">密码管理</div>
                <div class="text-muted">
                  变更自己的密码&emsp;&emsp;&emsp;
                  <router-link to="/password">
                    <span class="label label-light-primary label-inline font-weight-bold">管理</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end:Item-->
          <!--begin::Item-->
          <div class="navi-item">
            <div class="navi-link">
              <div class="symbol symbol-40 bg-light mr-3">
                <div class="symbol-label">
                  <span class="svg-icon svg-icon-md svg-icon-success">
                    <!--begin::Svg Icon-->
                    <inline-svg src="media/svg/misc/015-telegram.svg"/>
                    <!--end::Svg Icon-->
                  </span>
                </div>
              </div>
              <div class="navi-text">
                <div class="font-weight-bold">手机号码管理</div>
                <div class="text-muted">
                  管理账号的手机号码&emsp;
                  <router-link to="/phone">
                    <span class="label label-light-primary label-inline font-weight-bold">管理</span>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
          <!--end:Item-->
        </div>
      </perfect-scrollbar>
      <!--end::Content-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
#kt_quick_user {
  overflow: hidden;
}
</style>

<script>
import { LOGOUT } from "@/core/services/store/auth.module";
import KTLayoutQuickUser from "@/assets/js/layout/extended/quick-user.js";

export default {
  name: "KTQuickUser",
  computed: {
    user: {
      get () {
        return this.$store.getters.currentUser;
      }
    }
  },
  data() {
    return {
      temp:0,
    };
  },
  mounted() {
    // Init Quick User Panel
    KTLayoutQuickUser.init(this.$refs["kt_quick_user"]);
  },
  methods: {
    onLogout() {
      this.$store.dispatch(LOGOUT).then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>
